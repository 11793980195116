import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useRef } from 'react';

import {
  DocErrorFunction,
  FIRESTORE_PATH,
  StreamLanguageData,
} from '@/constants/firestore';
import { firestore } from '@/utils/firebases/firebaseFirestore';

/**
 * 対象ドキュメントの変更を検知したら実行したい関数の型
 */
export type StreamLanguageChangesFunction = (
  streamLanguageData: StreamLanguageData,
) => void;

/**
 * プロパティ
 */
type Props = {
  // 監視対象のFirestoreのドキュメントID
  documentId: string;
  // 変更を受け取る
  onDocChanges: StreamLanguageChangesFunction;
  // firestoreへの接続失敗・切断されたことを受け取る
  onDocError: DocErrorFunction;
};

/**
 * Firestore ストリーム言語 監視 hooks
 *
 * @param documentId 監視対象のFirestoreのドキュメントID
 * @param onDocChanges ストリーム言語の変更結果を受け取る
 * @param onDocError firestoreへの接続失敗・切断されたことを受け取る
 * @returns
 */
export const useStreamLanguagesCollection = ({
  documentId,
  onDocChanges,
  onDocError,
}: Props) => {
  const unsubscribeRef = useRef<Unsubscribe | undefined>(undefined);

  /**
   * onDocChangesをRefで管理
   */
  const onDocChangesRef = useRef<StreamLanguageChangesFunction>(onDocChanges);
  useEffect(() => {
    onDocChangesRef.current = onDocChanges;
  }, [onDocChanges]);

  /**
   * onDocErrorをRefで管理
   */
  const onDocErrorRef = useRef<DocErrorFunction>(onDocError);
  useEffect(() => {
    onDocErrorRef.current = onDocError;
  }, [onDocError]);

  /**
   * Firestoreの「ストリーム言語」を監視する
   */
  const subscribeStreamLanguages = useCallback(() => {
    // すでに監視済の場合はスキップ
    if (unsubscribeRef.current) {
      return;
    }

    const docRef = doc(
      firestore,
      FIRESTORE_PATH.COLLECTION.ITEMS,
      documentId,
      FIRESTORE_PATH.COLLECTION.LANGUAGES,
      FIRESTORE_PATH.DOCUMENT.LANGUAGE,
    );

    // 監視開始
    unsubscribeRef.current = onSnapshot(
      docRef,
      async (docSnap) => {
        const docData = docSnap.data();
        if (!docData) {
          return;
        }

        const json = JSON.stringify(docData);
        const languagesData: StreamLanguageData = JSON.parse(json);
        if (!languagesData) {
          return;
        }
        onDocChangesRef.current?.(languagesData);
      },
      async (e) => {
        if (documentId && e.code === 'permission-denied') {
          return; // 監視成功後にdocumentIdがある(Firestoreにログイン済)&&権限エラーになった場合はスキップ
        }
        onDocErrorRef.current?.();
      },
    );
  }, [documentId]);

  /**
   * Firestoreのデータ監視をデタッチする
   */
  const unsubscribeStreamLanguages = useCallback(
    () => unsubscribeRef.current?.(),
    [],
  );

  useEffect(() => {
    // マウント時に監視を開始
    subscribeStreamLanguages();

    return () => {
      // アンマウント時に解放
      unsubscribeStreamLanguages();
    };

    // マウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // Firestoreの「ストリーム言語」を監視する
    subscribeStreamLanguages,
  };
};
