import { Buffer } from 'buffer';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DEFAULT_INTERIM_CONTENT,
  InterimContent,
  PAGE_ROUTE_PATH,
  RecognizedItem,
} from '@/constants';
import { STREAM_API_RESULT_CODE, StreamApiResponse } from '@/features/api';
import { useHistoryDownload } from '@/features/history';
import { useMic, useVoiceInput } from '@/features/recognition';
import { useTextToSpeech } from '@/features/texttospeech';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { useWakeLock } from '@/hooks/useWakeLock';
import {
  RETRY_STATUS,
  STT_ERROR_TYPE,
  STT_STATUS,
} from '@/states/slices/translationInfoSlice';
import { convertDateToYyyyMMddTHhmmssUTC, currentDateUTC } from '@/utils/date';
import { canDisplayTranslationText } from '@/utils/translation';

import {
  ContinuousTimeOverFunction,
  useContinuousTimer,
} from '../timer/useContinuousTimer';
import {
  SilenceTimeOverFunction,
  useSilenceTimer,
} from '../timer/useSilenceTimer';
import { useBeforeUnload } from '../useBeforeUnload';

window.Buffer = Buffer;

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // [STT ON/OFF]ボタンがクリックされた際の処理
  clickSttButton: () => void;
  // リアルタイム音声認識結果
  interimContent: InterimContent;
  // マイク認識エラーダイアログで[OK]ボタンがクリックされた際の処理
  clickMicErrorDialogButton: () => void;
};

/**
 * 翻訳画面(マイク翻訳モード) hooks
 *
 * @returns
 */
export const useTranslationMic = (): Value => {
  const navigate = useNavigate();

  // リアルタイム音声認識結果
  const [interimContent, setInterimContent] = useState<InterimContent>(
    DEFAULT_INTERIM_CONTENT,
  );

  const {
    licenseToken,
    sttStatus,
    addNewRecognizedItem,
    recognizedList,
    setSttErrorType,
    retryStatus,
  } = useTranslationInfo();
  const { requestStartMic, startRecording, stopRecording } = useMic();
  const { addTtsList } = useTextToSpeech();
  const { srclang, destlang } = useBrowserLanguage();
  const { addNewRecognizedItemToHistory } = useHistoryDownload();
  const { addWakeLock, removeWakeLock } = useWakeLock();
  const { isConferenceMode } = useBrowserUserInfo();

  /**
   * タブを閉じる、ブラウザを閉じる、更新による画面遷移を制御
   */
  useBeforeUnload();

  /**
   * 5分無音監視 hooks
   */
  const { reObserveSilence, disconnectSilence } = useSilenceTimer();
  /**
   * 5分無音が続いた際に実行したい関数Ref
   */
  const silenceTimeOverRef = useRef<SilenceTimeOverFunction>();
  /**
   * 90分連続使用監視 hooks
   */
  const { reObserveContinuous, disconnectContinuous } = useContinuousTimer();
  /**
   * 5分無音が続いた際に実行したい関数Ref
   */
  const continuousTimeOverRef = useRef<ContinuousTimeOverFunction>();

  /**
   * 翻訳一覧の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const recognizedListRef = useRef<RecognizedItem[]>(recognizedList);
  useEffect(() => {
    recognizedListRef.current = recognizedList;
  }, [recognizedList]);

  /**
   * ライセンストークンの変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const licenseTokenRef = useRef<string>(licenseToken);
  useEffect(() => {
    licenseTokenRef.current = licenseToken;
  }, [licenseToken]);

  /**
   * 翻訳元言語の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const srclangRef = useRef<string>(srclang);
  useEffect(() => {
    srclangRef.current = srclang;
  }, [srclang]);

  /**
   * 翻訳先言語の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const destlangRef = useRef<string>(destlang);
  useEffect(() => {
    destlangRef.current = destlang;
  }, [destlang]);

  /**
   * 暫定TTTテキストの変更を監視してuseRefに格納
   *
   * イベントリスナー内の値はキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const interimTTTRef = useRef<string>('');

  /**
   * 音声ストリームAPIから受け取った音声認識結果
   */
  const onRecognitionResult = useCallback(
    (response: StreamApiResponse) => {
      // 5分無音監視を再登録
      reObserveSilence(silenceTimeOverRef.current);

      if (response.resultCode !== STREAM_API_RESULT_CODE.OK) {
        // 音声認識に失敗している場合は何もしない
        return;
      }
      if (
        !canDisplayTranslationText(
          srclangRef.current,
          destlangRef.current,
          response.srclang,
          response.destlang,
        )
      ) {
        // 通訳画面で選択した翻訳先言語以外の結果は表示しない
        return;
      }
      // 反転フラグ
      const isReversed =
        response.srclang !== srclangRef.current &&
        response.srclang === destlangRef.current;
      if (response.isFinal) {
        // 確定後はリアルタイム結果を消去
        setInterimContent(DEFAULT_INTERIM_CONTENT);
        interimTTTRef.current = '';
        // 通訳データ
        const recognizedItem: RecognizedItem = {
          id: recognizedListRef.current.length + 1,
          value: {
            stt: response.stt,
            ttt: response.ttt,
            srclang: response.srclang,
            destlang: response.destlang,
            date: convertDateToYyyyMMddTHhmmssUTC(currentDateUTC()),
            isReversed,
          },
        };
        // stt, ttt 結果をReduxに追加
        addNewRecognizedItem(recognizedItem);
        // stt, ttt 結果をローカルストレージの履歴に追加
        addNewRecognizedItemToHistory(recognizedItem);
        // 音声読み上げリストに追加
        addTtsList(
          isReversed ? response.stt : response.ttt,
          destlangRef.current,
        );
      } else {
        // 空ではない暫定TTTを保持(空のTTTが返ってきたら前回の暫定TTTを表示するため)
        if (response.ttt) {
          interimTTTRef.current = response.ttt;
        }
        // 音声認識中テキスト更新
        setInterimContent({
          stt: response.stt,
          ttt: interimTTTRef.current,
          srclang: response.srclang,
          destlang: response.destlang,
          isReversed,
        });
      }
    },
    [
      reObserveSilence,
      addNewRecognizedItem,
      addNewRecognizedItemToHistory,
      addTtsList,
    ],
  );

  /**
   * 音声入力 hooks
   */
  const { requestStartStream, closeStream, sendAudio } = useVoiceInput({
    onResult: onRecognitionResult,
    startAudioContext: startRecording,
    stopAudioContext: stopRecording,
  });

  /**
   * マイク入力結果を音声ストリームAPIに渡す
   */
  const onMicInputResult = useCallback(
    (data: any) => {
      sendAudio(data);
    },
    [sendAudio],
  );

  /**
   * [STT ON/OFF]ボタンがクリックされた際の処理
   */
  const clickSttButton = useCallback(() => {
    // 音声認識開始
    if (sttStatus === STT_STATUS.PAUSED || sttStatus === STT_STATUS.INACTIVE) {
      requestStartStream();

      return;
    }

    // 音声認識停止
    if (sttStatus === STT_STATUS.CONNECTING) {
      closeStream();
      // リアルタイム結果を消去
      setInterimContent(DEFAULT_INTERIM_CONTENT);
    }
  }, [closeStream, requestStartStream, sttStatus]);

  /**
   * リトライが開始されたらリアルタイムSTT結果を消去
   */
  useEffect(() => {
    if (retryStatus === RETRY_STATUS.RETRYING) {
      setInterimContent(DEFAULT_INTERIM_CONTENT);
    }
  }, [retryStatus]);

  const clickMicErrorDialogButton = () => {
    navigate(PAGE_ROUTE_PATH.HOME);
  };

  /**
   * 無音が既定時間経過した際に実行する処理
   */
  const silenceTimeOver = useCallback(() => {
    // 5分無音エラーダイアログを表示
    setSttErrorType(STT_ERROR_TYPE.SILENCE);
    // [STT停止]ボタンクリック
    clickSttButton();
  }, [clickSttButton, setSttErrorType]);
  useEffect(() => {
    silenceTimeOverRef.current = silenceTimeOver;
  }, [silenceTimeOver]);

  /**
   * 連続使用が既定時間経過した際に実行する処理
   */
  const continuousTimeOver = useCallback(() => {
    // 90分連続使用エラーダイアログを表示
    setSttErrorType(STT_ERROR_TYPE.CONSECUTIVELY);
    // [STT停止]ボタンクリック
    clickSttButton();
  }, [clickSttButton, setSttErrorType]);
  useEffect(() => {
    continuousTimeOverRef.current = continuousTimeOver;
  }, [continuousTimeOver]);

  /**
   * 画面表示(マウント時/アンマウント時)
   */
  useEffect(() => {
    // マイク許可
    requestStartMic(onMicInputResult);
    // 画面暗転・ロック制御
    addWakeLock();

    return () => {
      // 画面暗転・ロック制御を解除
      removeWakeLock();
    };

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Websocketへの接続状態を監視
   */
  useEffect(() => {
    // Websocketに接続中
    if (sttStatus === STT_STATUS.CONNECTING) {
      // 5分無音監視を再登録
      reObserveSilence(silenceTimeOverRef.current);
      // 90分連続使用監視を再登録(カンファレンス用シリアル以外でログイン中の場合のみ有効)
      if (!isConferenceMode) {
        reObserveContinuous(continuousTimeOverRef.current);
      }

      return;
    }

    // Websocketへの接続停止
    if (sttStatus === STT_STATUS.PAUSED) {
      // 5分無音監視,90分連続使用監視を削除
      // 途中でリトライするときは必ずSTTが停止されてからREADY状態になるので停止時にのみ削除でOK
      disconnectSilence();
      disconnectContinuous();
    }
  }, [
    disconnectContinuous,
    disconnectSilence,
    isConferenceMode,
    reObserveContinuous,
    reObserveSilence,
    sttStatus,
  ]);

  return {
    clickSttButton,
    interimContent,
    clickMicErrorDialogButton,
  };
};
