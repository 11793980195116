import React from 'react';
import { useTranslation } from 'react-i18next';

import screenShare from '@/assets/screen_share.svg';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

/**
 * プロパティ
 */
type Props = {
  onCloseMenu: () => void;
};
/**
 * ヘッダー > メインメニュー > 通訳を共有するメニュー
 */
export const ShareInfoContainer = React.memo(({ onCloseMenu }: Props) => {
  const { t } = useTranslation();
  const { setIsOpenShareDialog } = useShareViewInfo();

  return (
    <button
      type="button"
      onClick={() => {
        setIsOpenShareDialog(true);
        onCloseMenu();
      }}
      className="main-menu-02"
    >
      <img src={screenShare} alt="screenShare" />
      {t('mainMenu.共有URLを表示/終了')}
    </button>
  );
});
