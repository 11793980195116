import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VIEW_CONFIG_STATUS, ViewConfigStatus } from '@/constants/firestore';

/**
 * 共有画面の共有状況
 */
export const SHARE_VIEW_STATUS = {
  // 共有前
  NONE: 'none',
  // 共有中
  SHARED: 'shared',
  // 共有画面情報取得に失敗
  SHARE_INFO_ERROR: 'shareInfoError',
  // アクセス期限切れ
  WARN_AUTH: 'warnAuth',
};
export type ShareViewStatus =
  (typeof SHARE_VIEW_STATUS)[keyof typeof SHARE_VIEW_STATUS];

/**
 * State
 */
export type ShareViewInfoState = {
  // 共有画面の共有状況
  shareViewStatus: ShareViewStatus;
  // 共有画面のURLキー
  shareURLKey: string;
  // ゲスト画面のCSVダウンロード許可値
  isCSVDownloadGuest: boolean;
  // 共有画面の一時停止/再開の状態
  sharePauseResumeStatus: ViewConfigStatus;
  // FirestoreのドキュメントID
  uid: string;
  // 共有画面の利用者合計
  totalGuests: number;
  // 共有可能な人数の上限
  maxGuests: number;
  // 共有ダイアログを表示するか否か
  isOpenShareDialog: boolean;
  // 共有復帰ダイアログを表示するか否か
  isOpenShareRejoinDialog: boolean;
  // 共有一時停止完了ダイアログを表示するか否か
  isOpenSharePauseCompleteDialog: boolean;
  // 共有再開完了ダイアログを表示するか否か
  isOpenShareResumeCompleteDialog: boolean;
};

/**
 * 初期State
 */
const initialState: ShareViewInfoState = {
  shareViewStatus: SHARE_VIEW_STATUS.NONE,
  shareURLKey: '',
  isCSVDownloadGuest: false,
  sharePauseResumeStatus: VIEW_CONFIG_STATUS.ACTIVE,
  uid: '',
  totalGuests: 0,
  maxGuests: 0,
  isOpenShareDialog: false,
  isOpenShareRejoinDialog: false,
  isOpenSharePauseCompleteDialog: false,
  isOpenShareResumeCompleteDialog: false,
};

/**
 * 共有画面情報 Slice
 */
export const shareViewInfoSlice = createSlice({
  name: 'shareViewInfo',
  initialState,
  reducers: {
    // 共有画面の共有状況を更新
    setShareViewStatus: (state, action: PayloadAction<ShareViewStatus>) => {
      state.shareViewStatus = action.payload;
    },
    // 共有画面のURLキーを更新
    setShareURLKey: (state, action: PayloadAction<string>) => {
      state.shareURLKey = action.payload;
    },
    // ゲスト画面のCSVダウンロード許可値を更新
    setIsCSVDownloadGuest: (state, action: PayloadAction<boolean>) => {
      state.isCSVDownloadGuest = action.payload;
    },
    // 共有画面の一時停止/再開の状態を更新
    setSharePauseResumeStatus: (
      state,
      action: PayloadAction<ViewConfigStatus>,
    ) => {
      state.sharePauseResumeStatus = action.payload;
    },
    // FirestoreのドキュメントIDを更新
    setUid: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    // 共有画面の利用者合計を更新
    setTotalGuests: (state, action: PayloadAction<number>) => {
      state.totalGuests = action.payload;
    },
    // 共有可能な人数の上限を更新
    setMaxGuests: (state, action: PayloadAction<number>) => {
      state.maxGuests = action.payload;
    },
    // 共有ダイアログを表示するか否か
    setIsOpenShareDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareDialog = action.payload;
    },
    // 共有復帰ダイアログを表示するか否か
    setIsOpenShareRejoinDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareRejoinDialog = action.payload;
    },
    // 共有一時停止完了ダイアログを表示するか否かの判定値を更新
    setIsOpenSharePauseCompleteDialog: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isOpenSharePauseCompleteDialog = action.payload;
    },
    // 共有再開完了ダイアログを表示するか否かの判定値を更新
    setIsOpenShareResumeCompleteDialog: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isOpenShareResumeCompleteDialog = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
