import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * リロード催促ダイアログ プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * リロード催促ダイアログ
 */
export const ReloadPromptDialog = React.memo(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t(
        'reloadPromptDialog.続けて通訳結果を見る場合は、画面をリロードしてください',
      ).toString()}
      buttons={[{ label: 'OK', onClick: onClose, gtmClassTag: 'dialog-21' }]}
    >
      <DialogMessageContents> </DialogMessageContents>
    </ModalDialog>
  );
});
