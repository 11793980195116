/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationMessageOverlayView } from '@/components/Elements/MessageOverlayView';
import { TRANSLATION_LIST_VIEW_TYPE } from '@/constants';
import { DocErrorFunction } from '@/constants/firestore';
import {
  RecognizedTextsAddFunction,
  InterimTextsAddFunction,
  useRecognizedTextsCollection,
  useInterimTextsCollection,
  useViewConfigCollection,
  ViewConfigChangesFunction,
  StreamLanguageChangesFunction,
  useStreamLanguagesCollection,
} from '@/features/firestore';

import { ShareActiveDialog } from './dialog/ShareActiveDialog';
import { ShareInactiveDialog } from './dialog/ShareInactiveDialog';

/**
 * プロパティ
 */
type Props = {
  // 翻訳先言語
  destlang: string;
  // 「共有画面設定(view>config)」が更新された時の処理
  onChangeViewConfig: ViewConfigChangesFunction;
  // 「ストリーム言語(languages>language)」が更新された時の処理
  onChangeStreamLanguage: StreamLanguageChangesFunction;
  // 「暫定テキスト(interim_texts/languages)」が追加された際の処理
  onResultAddInterimTexts: InterimTextsAddFunction;
  // 「確定テキスト(recognized_texts)」が追加された際の処理
  onResultAddRecognizedTexts: RecognizedTextsAddFunction;
  // Firestoreへの接続に失敗した際の処理
  onErrorFireStore: DocErrorFunction;
  // Firestoreの監視に使うuid(ゲスト画面表示中は使いまわす)
  firestoreDocumentId: string;
  // 共有再開お知らせダイアログを表示するか否か
  isOpenShareActiveDialog: boolean;
  // 共有再開お知らせダイアログのOKボタンクリック時の処理
  onClickOKShareActiveDialog: () => void;
  // 共有停止お知らせダイアログを表示するか否か
  isOpenShareInactiveDialog: boolean;
  // 共有停止お知らせダイアログのOKボタンクリック時の処理
  onClickOKShareInactiveDialog: () => void;
  // 共有中か否か
  isHostShareActive: boolean;
};

/**
 * 共有機能用 ゲスト画面ビュー
 */
export const ShareGuestView = React.memo(
  ({
    destlang,
    onChangeViewConfig,
    onChangeStreamLanguage,
    onResultAddInterimTexts,
    onResultAddRecognizedTexts,
    onErrorFireStore,
    firestoreDocumentId,
    isOpenShareActiveDialog,
    onClickOKShareActiveDialog,
    isOpenShareInactiveDialog,
    onClickOKShareInactiveDialog,
    isHostShareActive,
  }: Props) => {
    // 暫定テキストの監視開始
    useInterimTextsCollection({
      documentId: firestoreDocumentId,
      destlang,
      onResultAddData: onResultAddInterimTexts,
      onDocError: onErrorFireStore,
    });
    // ストリーム言語を監視
    useStreamLanguagesCollection({
      documentId: firestoreDocumentId,
      onDocChanges: onChangeStreamLanguage,
      onDocError: onErrorFireStore,
    });
    // 確定テキストの監視開始
    useRecognizedTextsCollection({
      documentId: firestoreDocumentId,
      destlang,
      onResultAddData: onResultAddRecognizedTexts,
      onDocError: onErrorFireStore,
    });
    // 共有状況の監視
    useViewConfigCollection({
      documentId: firestoreDocumentId,
      onDocChanges: onChangeViewConfig,
      onDocError: onErrorFireStore,
    });
    const { t } = useTranslation();

    return (
      <>
        {/* 共有再開お知らせダイアログを表示 */}
        <ShareActiveDialog
          isOpen={isHostShareActive && isOpenShareActiveDialog}
          onClickOKButton={onClickOKShareActiveDialog}
        />

        {/* 共有停止お知らせダイアログを表示 */}
        <ShareInactiveDialog
          isOpen={!isHostShareActive && isOpenShareInactiveDialog}
          onClickOKButton={onClickOKShareInactiveDialog}
        />

        {/* 共有停止時に共有停止中レイヤーを表示 */}
        {!isHostShareActive && (
          <TranslationMessageOverlayView
            isOpen
            text={t('shareHost.共有停止中')}
            translationListViewType={TRANSLATION_LIST_VIEW_TYPE.CONFERENCE}
          />
        )}
      </>
    );
  },
);
