import React from 'react';
import { useTranslation } from 'react-i18next';

import download from '@/assets/download.svg';
import useOpen from '@/hooks/useOpen';
import { isAppleMobileDevice } from '@/utils/device';

import { useGuestTranslationDownload } from '../../hooks/useGuestTranslationDownload';
import { ReloadPromptDialog } from '../ReloadPromptDialog';

/**
 * プロパティ
 */
type Props = {
  // 表示メニューを閉じる処理
  onCloseMenu: () => void;
};

/**
 * ゲスト画面 CSVダウンロードメニュー コンポーネント
 */
export const GuestCSVDownloadContainer = React.memo(
  ({ onCloseMenu }: Props) => {
    const { t } = useTranslation();
    const { onCsvDownloadButton } = useGuestTranslationDownload();
    const { isOpen, onOpen, onClose } = useOpen();

    return (
      <>
        <ReloadPromptDialog isOpen={isOpen} onClose={onClose} />
        <button
          type="button"
          onClick={() => {
            onCloseMenu();
            onCsvDownloadButton();
            if (isAppleMobileDevice()) {
              onOpen();
            }
          }}
          className="guest-05"
          data-testid="share-csv-download"
        >
          <img src={download} alt="download" />
          {t('shareGuest.通訳履歴をダウンロード')}
        </button>
      </>
    );
  },
);
