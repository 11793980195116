import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * VAD閾値の種別
 */
export const VAD_THRESHOLD_TYPE = {
  // 0.5
  MEDIUM: 'medium',
  // 0.8
  HIGH: 'high',
};
export type VadThresholdType =
  (typeof VAD_THRESHOLD_TYPE)[keyof typeof VAD_THRESHOLD_TYPE];

/**
 * VAD閾値のデフォルト値
 */
export const VAD_THRESHOLD_DEFAULT_VALUE = VAD_THRESHOLD_TYPE.HIGH;

/**
 * [オーディオ設定ダイアログ]の設定内容を管理 State
 */
export type BrowserAudioSettingState = {
  // 入力元デバイス
  inputDevice: string;
  // 出力元デバイス
  outputDevice: string;
  // 音声区間検出値
  vadThreshold: VadThresholdType;
};

/**
 * 初期State
 */
const initialState: BrowserAudioSettingState = {
  inputDevice: '',
  outputDevice: '',
  vadThreshold: '',
};

/**
 * [オーディオ設定ダイアログ]の設定内容を管理 Slice
 */
export const browserAudioSettingSlice = createSlice({
  name: 'browserAudioSetting',
  initialState,
  reducers: {
    // 入力元デバイスを更新
    setInputDevice: (state, action: PayloadAction<string>) => {
      state.inputDevice = action.payload;
    },
    // 出力元デバイスを更新
    setOutputDevice: (state, action: PayloadAction<string>) => {
      state.outputDevice = action.payload;
    },
    // 音声区間検出値を更新
    setAudioThreshold: (state, action: PayloadAction<VadThresholdType>) => {
      state.vadThreshold = action.payload;
    },
    // 初期化
    clearAudioSetting: () => initialState,
  },
});
