import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
  POPUP_CONTENT_ALIGN_POSITION,
  PopupContents,
  PopupDetails,
  PopupSummary,
} from '@/components/Elements';
import { DisplayMenuContainer } from '@/features/display';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useKeyDown } from '@/hooks/useKeyDown';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

import { useTranslationPath } from '../../hooks/useTranslationPath';
import { SettingAudioContainer } from '../audio/SettingAudioContainer';
import { FeedbackContainer } from '../feedback/FeedbackContainer';
import styles from '../Header.module.scss';
import { HistoryDownloadContainer } from '../history/HistoryDownloadContainer';
import { LicenseInfoContainer } from '../license/LicenseInfoContainer';
import { LogoutContainer } from '../logout/LogoutContainer';
import { ManualContainer } from '../manual/ManualContainer';
import { PlanContainer } from '../plan/PlanContainer';
import { ShareInfoContainer } from '../share/ShareInfoContainer';
import { ShareStartContainer } from '../share/ShareStartContainer';
import { UILanguageContainer } from '../uilanguage/UILanguageContainer';

import { TtsMenuContainer } from './TtsMenuContainer';

/**
 * ヘッダー > メインメニュー
 */
export const MainMenuContainer = React.memo(() => {
  // メニュークリックでopen要素削除する
  const detailsRef = useRef<HTMLDetailsElement | null>(null);
  const { pathname } = useLocation();
  const { isTranslationPath } = useTranslationPath();
  const { shareViewStatus, isShared } = useShareViewInfo();
  const { displayType, setDisplayType } = useBrowserTranslationDisplay();
  const { isConferenceMode } = useBrowserUserInfo();

  /**
   * クローズ処理
   */
  const onClose = (): void => {
    detailsRef.current?.removeAttribute('open');
  };
  // Escキーが押下されたらメニューを閉じる
  useKeyDown({ onEscapeKeyDown: onClose });

  return (
    <div>
      <PopupDetails detailsRef={detailsRef}>
        <PopupSummary>
          <div className={styles.menu}>
            <div className={styles['main-menu']} data-testid="main-menu" />
          </div>
        </PopupSummary>
        <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.RIGHT}>
          <div className={styles['menu-contents']}>
            <ul>
              {/* オーディオ設定メニュー */}
              <li>
                <SettingAudioContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
              {/* 通訳履歴をダウンロードメニュー */}
              <li>
                <HistoryDownloadContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
              {isTranslationPath(pathname) ? (
                <>
                  <li>
                    {/* 翻訳元テキストの表示/非表示切り替えメニュー(翻訳画面でのみ表示) */}
                    <DisplayMenuContainer
                      onCloseMenu={onClose}
                      currentDisplayType={displayType}
                      onChangeDisplayTypeFunc={setDisplayType}
                      gtmClassTag="dts-transswitch"
                    />
                  </li>
                  <li>
                    {/* 翻訳音声を再生/翻訳音声を停止メニュー(翻訳画面でのみ表示) */}
                    <TtsMenuContainer
                      onCloseMenu={() => {
                        detailsRef.current?.removeAttribute('open');
                      }}
                    />
                  </li>
                </>
              ) : (
                ''
              )}
              {/* 共有メニュー */}
              {isTranslationPath(pathname) && isConferenceMode && (
                <>
                  <li className={isShared ? styles['menu-display-none'] : ''}>
                    {/* 共有を開始メニュー */}
                    <ShareStartContainer
                      onCloseMenu={() => {
                        onClose();
                      }}
                    />
                  </li>
                  <li
                    className={
                      shareViewStatus === SHARE_VIEW_STATUS.NONE
                        ? styles['menu-display-none']
                        : ''
                    }
                  >
                    {/* 共有URLを表示/終了メニュー */}
                    <ShareInfoContainer
                      onCloseMenu={() => {
                        onClose();
                      }}
                    />
                  </li>
                </>
              )}
              {/* プランメニュー */}
              <li>
                <PlanContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
              {/* ライセンス情報メニュー */}
              <li>
                <LicenseInfoContainer onCloseMenu={onClose} />
              </li>
              {/* マニュアルメニュー */}
              <li>
                <ManualContainer onCloseMenu={onClose} />
              </li>
              {/* フェードバックメニュー */}
              <li>
                <FeedbackContainer onCloseMenu={onClose} />
              </li>
              {/* UI言語メニュー */}
              <li>
                <UILanguageContainer onCloseMenu={onClose} />
              </li>
              {/* 罫線 */}
              <li className={styles['menu-separate']}>
                <div />
              </li>
              {/* ログアウト */}
              <li>
                <LogoutContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
            </ul>
          </div>
        </PopupContents>
      </PopupDetails>
    </div>
  );
});
