import React from 'react';
import { useTranslation } from 'react-i18next';

import useOpen from '@/hooks/useOpen';

import styles from './SettingAudioButton.module.scss';
import { SettingAudioDialog } from './SettingAudioDialog';

/**
 * オーディオ設定ボタン
 */
export const SettingAudioButton = React.memo(() => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();

  return (
    <button className={`${styles['setting-audio']} footer-01`} onClick={onOpen}>
      <div className={`${styles['setting-audio-icon']}`} />
      {t('mainMenu.オーディオ設定')}
      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <SettingAudioDialog isOpen onClose={onClose} />}
    </button>
  );
});
