import React from 'react';
import { Navigate } from 'react-router';

import { PAGE_ROUTE_PATH } from '@/constants';
import { ShareGuestBannerMessageMap } from '@/constants/share';
import { ConferenceView } from '@/features/conference';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';
import { GUEST_STATUS_TYPE } from '@/states/slices/guestDisplaySlice';
import { isGuestCampaignActive } from '@/utils/campaign';

import { useShareGuestControl } from '../hooks/useShareGuestControl';
import { useWelcomeGuestDialog } from '../hooks/useWelcomeGuestDialog';

import { ShareGuestBanner } from './banner/ShareGuestBanner';
import { CampaignButton } from './CampaignButton';
import { GuestErrorView } from './GuestErrorView';
import { ShareGuestMenuContainer } from './menu/ShareGuestMenuContainer';
import { ShareEndView } from './ShareEndView';
import { ShareGuestView } from './ShareGuestView';
import { TotalGuestOverView } from './TotalGuestOverView';
import { WelcomeGuestDialog } from './WelcomeGuestDialog';

/**
 * ゲスト画面 データ管理用 コンポーネント
 * ※データを取得してゲスト画面の表示内容を切り替えるためのコンポーネント
 */
export const ShareGuestControl = React.memo(() => {
  const {
    interimContent,
    recognizedList,
    destlang,
    destlangPullDownList,
    onChangeDestlang,
    onChangeViewConfig,
    onChangeStreamLanguage,
    onResultAddInterimTexts,
    onResultAddRecognizedTexts,
    firestoreConnectionError,
    firestoreDocumentId,
  } = useShareGuestControl();

  const { isWelcomeGuestDialogOpen, clickWelcomeGuestOKButton } =
    useWelcomeGuestDialog();

  const {
    fontSize,
    setFontSize,
    displayType,
    guestStatus,
    isOpenShareActiveDialog,
    setIsOpenShareActiveDialog,
    isOpenShareInactiveDialog,
    setISOpenShareInactiveDialog,
    isHostShareActive,
  } = useGuestDisplay();

  // 共有状況ステータスバー
  const shareGuestStatusBanner = () => {
    const bannerMessage = ShareGuestBannerMessageMap[guestStatus];
    if (!bannerMessage) {
      return undefined;
    }

    return <ShareGuestBanner bannerMessage={bannerMessage} />;
  };

  return (
    <div>
      <WelcomeGuestDialog
        isOpen={isWelcomeGuestDialogOpen}
        onClose={clickWelcomeGuestOKButton}
      />
      {/* ゲスト向けキャンペーンが有効な場合はキャンペーンボタン表示 */}
      {isGuestCampaignActive() && <CampaignButton />}

      {guestStatus === GUEST_STATUS_TYPE.SUCCESS && firestoreDocumentId && (
        // 共有機能用ゲスト画面を表示
        <ShareGuestView
          destlang={destlang}
          onChangeViewConfig={onChangeViewConfig}
          onChangeStreamLanguage={onChangeStreamLanguage}
          onResultAddInterimTexts={onResultAddInterimTexts}
          onResultAddRecognizedTexts={onResultAddRecognizedTexts}
          onErrorFireStore={firestoreConnectionError}
          firestoreDocumentId={firestoreDocumentId}
          isOpenShareActiveDialog={isOpenShareActiveDialog}
          onClickOKShareActiveDialog={() => setIsOpenShareActiveDialog(false)}
          isOpenShareInactiveDialog={isOpenShareInactiveDialog}
          onClickOKShareInactiveDialog={() =>
            setISOpenShareInactiveDialog(false)
          }
          isHostShareActive={isHostShareActive}
        />
      )}

      {guestStatus === GUEST_STATUS_TYPE.INVALID_URL_KEY && (
        // 404エラー画面にリダイレクト
        <Navigate replace to={PAGE_ROUTE_PATH.NOT_FOUND} />
      )}

      {guestStatus === GUEST_STATUS_TYPE.SHARE_END && (
        // 共有終了ビューを表示
        <ShareEndView />
      )}

      {guestStatus === GUEST_STATUS_TYPE.OVER_LIMIT && (
        // 共有人数上限ビューを表示
        <TotalGuestOverView />
      )}

      {guestStatus === GUEST_STATUS_TYPE.OTHER && (
        // ゲスト画面エラービューを表示
        <GuestErrorView />
      )}

      {/* 翻訳結果表示ビュー */}
      <ConferenceView
        interimContent={interimContent}
        recognizedTexts={recognizedList}
        menuContent={<ShareGuestMenuContainer />}
        fontSize={fontSize}
        onChangeFontSizeFunc={setFontSize}
        displayType={displayType}
        destlang={destlang}
        destlangList={destlangPullDownList}
        onChangeDestlang={onChangeDestlang}
        isDisabledDestlangList={guestStatus !== GUEST_STATUS_TYPE.SUCCESS}
        shareGuestBanner={shareGuestStatusBanner()}
      />
    </div>
  );
});
