import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import uiLang from '@/assets/language.svg';
import useOpen from '@/hooks/useOpen';

import { useTranslationPath } from '../../hooks/useTranslationPath';

import { HostUILanguageDialog } from './HostUILanguageDialog';

/**
 * プロパティ
 */
type UILanguageContainer = {
  // 表示メニューを閉じる処理
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > システム言語メニュー
 */
export const UILanguageContainer = React.memo(
  ({ onCloseMenu }: UILanguageContainer) => {
    const { t } = useTranslation();
    const { isOpen, onClose, onOpen } = useOpen();
    const { pathname } = useLocation();
    const { isTranslationPath } = useTranslationPath();

    return (
      <>
        <button
          type="button"
          onClick={() => {
            onOpen();
            onCloseMenu();
          }}
          className={
            !isTranslationPath(pathname) ? 'main-menu-04' : 'main-menu-trans04'
          }
        >
          <img src={uiLang} alt="uiLang" />
          {t('mainMenu.システム言語')}
        </button>

        {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
        {isOpen && <HostUILanguageDialog isOpen onClose={onClose} />}
      </>
    );
  },
);
