import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ShareButton.module.scss';

/**
 * プロパティ
 */
type Props = {
  // 共有ダイアログを開く
  onOpenShareDialog: () => void;
};

/**
 * 共有設定ボタン コンポーネント
 *
 * @returns
 */
export const ShareButton = React.memo(({ onOpenShareDialog }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.share}>
      <button
        className={`${styles['share-button']} ${'header-01'}`}
        onClick={(e) => {
          onOpenShareDialog();
          e.stopPropagation();
        }}
        tabIndex={-1}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <span className={styles['share-icon']} />
        <div className={styles['share-button-container']}>
          {t('shareButton.共有設定')}
        </div>
      </button>
    </div>
  );
});
