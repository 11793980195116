import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import settingVoice from '@/assets/settings_voice.svg';
import { SettingAudioDialog } from '@/features/audio';
import useOpen from '@/hooks/useOpen';

import { useTranslationPath } from '../../hooks/useTranslationPath';

/**
 * プロパティ
 */
type Props = {
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > オーディオ設定メニュー
 */
export const SettingAudioContainer = React.memo(({ onCloseMenu }: Props) => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isTranslationPath } = useTranslationPath();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
        className={
          !isTranslationPath(pathname) ? 'main-menu-03' : 'main-menu-trans03'
        }
      >
        <img src={settingVoice} alt="setetingAudio" />
        {t('mainMenu.オーディオ設定')}
      </button>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <SettingAudioDialog isOpen onClose={onClose} />}
    </>
  );
});
